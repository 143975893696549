// Libraries
import React, { lazy, useEffect } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Loadable from "./loadable";

// Main Layout
import Main from "../layout/Main";
import { useSelector } from "react-redux";

// Lazy Load Components
// Dashboard
const Demo = Loadable(lazy(() => import('../pages/experiments/jm')))

const Dashboard = Loadable(lazy(() => import("../pages/dashboard/dashboard")));
const PanelQueriesListing = Loadable(
  lazy(() => import("../pages/panel-queries/panel-queries-listing"))
);
// Masters
const HRSettings = Loadable(
  lazy(() => import("../pages/settings/hr-settings/hr-settings"))
);

const TestTail = Loadable(lazy(() => import("../components/test")));
const EApps = Loadable(lazy(() => import("../pages/eApps-Iframe")));
const AddRole = Loadable(
  lazy(() => import("../pages/settings/hr-settings/sections/roles/role-form"))
);
const EditRole = Loadable(
  lazy(() => import("../pages/settings/hr-settings/sections/roles/role-form"))
);
const Holiday = Loadable(
  lazy(() => import("../pages/settings/hr-settings/sections/holidays/holidays"))
);

const EManageSettings = Loadable(
  lazy(() => import("../pages/settings/emanage-settings/emanage-settings"))
);
const ProductionSettings = Loadable(
  lazy(() =>
    import("../pages/settings/production-settings/production-settings")
  )
);

const ResourcePlannerTemplateForm = Loadable(
  lazy(() =>
    import(
      "../pages/settings/production-settings/sections/resource-planner-template/resource-planner-template-form"
    )
  )
);

const SoftwareVersions = Loadable(
  lazy(() =>
    import(
      "../pages/settings/production-settings/sections/softwares/software-versions"
    )
  )
);

const Agenda = Loadable(
  lazy(() =>
    import(
      "../pages/settings/production-settings/sections/meeting-types/agenda"
    )
  )
);

// Employee
const Employees = Loadable(lazy(() => import("../pages/employee/employee")));
const EmployeeForm = Loadable(
  lazy(() => import("../pages/employee/employee-form"))
);
const EmployeeDetails = Loadable(
  lazy(() => import("../pages/employee/employee-details"))
);

//Organisation
const Organisations = Loadable(
  lazy(() => import("../pages/organisation/organisation"))
);
const OrganisationForm = Loadable(
  lazy(() => import("../pages/organisation/organisation-form"))
);
const OrganisationsOverview = Loadable(
  lazy(() => import("../components/organisation/organisation-overview"))
);

//Contact
const Contacts = Loadable(lazy(() => import("../pages/contact/contact")));
const ContactForm = Loadable(
  lazy(() => import("../pages/contact/contact-form"))
);

//Resource Planner
const ResourcePlanner = Loadable(
  lazy(() => import("../pages/resource-planner/index"))
);
const ResourcePlannerForm = Loadable(
  lazy(() => import("../pages/resource-planner/resource-planner-form"))
);

// ReportBuilder;
const Reports = Loadable(lazy(() => import("../pages/report")));

const Reportlist = Loadable(lazy(() => import("../pages/reports/index")));

const ReportBuilder = Loadable(
  lazy(() => import("../pages/report/report-builder"))
);

const AnalyticalReport = Loadable(lazy(() => import("../pages/report/analytical")));


//Project
const CreateProjectonbording = Loadable(
  lazy(() => import("../pages/project/requestproject"))
);

const Projectlist = Loadable(
  lazy(() => import("../components/project/projectlist"))
);

const ProjectUnarchive = Loadable(
  lazy(() => import("../pages/archive-unarchive/project-unarchive"))
);

//clients
const Clients = Loadable(lazy(() => import("../pages/client/client")));
const Checklist = Loadable(lazy(() => import("../pages/checklist/index")));
const ClientsOverview = Loadable(
  lazy(() => import("../pages/client/client-overview"))
);

//sub project
const SubProjects = Loadable(
  lazy(() => import("../pages/subproject/subproject"))
);
const AddSubProject = Loadable(
  lazy(() => import("../components/subproject/AddSubProject"))
);
const SubProjectOverview = Loadable(
  lazy(() => import("../components/subproject/subproject-overview"))
);
const EditSubProject = Loadable(
  lazy(() => import("../components/subproject/EditSubProject"))
);
const SubProjectslist = Loadable(
  lazy(() => import("../components/subproject/subprojectlist"))
);

//Activities Files
//const ActivityFiles = Loadable(lazy(() => import('../components/activity/ActivityFiles')));
const ActivityFiles = Loadable(
  lazy(() => import("../pages/activity/activity"))
);
const EditActivityFiles = Loadable(
  lazy(() => import("../components/activity/editactivity"))
);
const AddActivity = Loadable(
  lazy(() => import("../components/activity/AddActivity"))
);
const ActivityFileslist = Loadable(
  lazy(() => import("../components/activity/activitylist"))
);
const ActivityOverview = Loadable(
  lazy(() => import("../pages/activity/activity-overview"))
);
const QualityCheck = Loadable(
  lazy(() => import("../pages/checklist/quality-check"))
);

//Timesheet
const TimeSheet = Loadable(lazy(() => import("../pages/timesheet/timesheet")));

//Deliverables
const Deliverables = Loadable(
  lazy(() => import("../components/deliverable/deliverablelist"))
);

const Loader = Loadable(lazy(() => import("../util/loader")));
// Icons import
const Allicons = Loadable(lazy(() => import("./../assets/allicons")));

// EPIR
const EpirProject = Loadable(
  lazy(() => import("../components/subproject/epirproject"))
);

//Conference
const Conference = Loadable(
  lazy(() => import("../pages/conference/conference"))
);
const AddConference = Loadable(
  lazy(() => import("../pages/conference/addconference"))
);
const BookConference = Loadable(
  lazy(() => import("../pages/conference/bookconference"))
);
const ConferenceViewDetails = Loadable(
  lazy(() => import("../pages/conference/conferenceviewdetails"))
);
const EditConference = Loadable(
  lazy(() => import("../pages/conference/editconference"))
);
const EditBookConference = Loadable(
  lazy(() => import("../pages/conference/editbookconference"))
);
const EditOccurrence = Loadable(
  lazy(() => import("../pages/conference/edit-occurrence"))
);

// Prject Query Imports
const ProjectQueries = Loadable(lazy(() => import("../pages/projectquery")));
const ProjectQuerySubListing = Loadable(
  lazy(() => import("../pages/projectquery/query-sub-listing"))
);
const RaiseQuery = Loadable(
  lazy(() => import("../pages/projectquery/raise-query"))
);

// Variation Order Imports
const IssueOrder = Loadable(
  lazy(() => import("../pages/variationorder/issue-order"))
);
const VariationOrders = Loadable(lazy(() => import("../pages/variationorder")));

const QueriesFeedback = Loadable(
  lazy(() => import("../pages/projectquery/feedback"))
);
const OrdersFeedback = Loadable(
  lazy(() => import("../pages/variationorder/feedback"))
);
const ViewQuery = Loadable(
  lazy(() => import("../pages/projectquery/view-query"))
);

const ViewOrder = Loadable(
  lazy(() => import("../pages/variationorder/view-order"))
);

const VariationOrderSubListing = Loadable(
  lazy(() => import("../pages/variationorder/order-sub-listing"))
);

//Trash list
const Trash = Loadable(lazy(() => import("../pages/trash/trash")));
const SQLBuilder = Loadable(
  lazy(() => import("../pages/experiments/sql-builder"))
);

const MinutesOfMeeting = Loadable(
  lazy(() => import("../pages/minutes-of-meeting/index"))
);

const MeetingNotesForm = Loadable(
  lazy(() => import("../pages/minutes-of-meeting/meeting-notes-form"))
);
const MeetingDetails = Loadable(
  lazy(() => import("../pages/minutes-of-meeting/meeting-details"))
);

//Client feedback
const ClientFeedback = Loadable(
  lazy(() => import("../pages/client-feedback/client-feedback"))
);

const ClientFeedbackForm = Loadable(
  lazy(() => import("../pages/client-feedback/client-feedback-form"))
);

const ClientFeddbackDetails = Loadable(
  lazy(() => import("../pages/client-feedback/client-feedback-details"))
);

//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
  // Variables
  const location = useLocation();


  useEffect(() => {
    if (!location.pathname.includes('/timesheets')) {
      localStorage.removeItem("employeeId");
      localStorage.removeItem("week");
      localStorage.removeItem('view');
      localStorage.removeItem('listType');

    }
  }, [location.pathname]);

  // Permissions
  const permissionsMatrix = useSelector(
    (state) => state.permissions.permissionsMatrix
  );

  const checkPermission = (moduleAlias, permissionAlias) => {
    if (permissionsMatrix && permissionsMatrix.length > 0) {
      const permission = permissionsMatrix.find(
        (element) =>
          element.permission.module.alias == moduleAlias &&
          element.permission.alias == permissionAlias
      );
      if (permission) {
        return permission.flag;
      }
      return 0;
    }
  };


  useEffect(() => {
    if (location.pathname != "/resource-planner") {
      localStorage.removeItem('filterObject');
    }

  }, [location])

  return (
    <>
      <Main>
        <Switch location={location} key={location.pathname}>
          <Route path="/dashboard" render={(props) => <Dashboard />} />
          {/* HR Settings */}
          {checkPermission("hr_settings", "edit") && (
            <Route
              path="/hr-settings/holiday-list/:id"
              render={(props) => <Holiday />}
            />
          )}
          {checkPermission("hr_settings", "view") && (
            <Route
              path="/hr-settings/:tab"
              render={(props) => <HRSettings />}
            />
          )}
          {checkPermission("hr_settings", "add") && (
            <Route
              path="/add-user-access-level"
              render={(props) => <AddRole />}
            />
          )}
          {checkPermission("hr_settings", "edit") && (
            <Route
              path="/edit-user-access-level/:id"
              render={(props) => <EditRole />}
            />
          )}
          {checkPermission("hr_settings", "edit") && (
            <Route
              path="/clone-user-access-level/:id"
              render={(props) => <EditRole />}
            />
          )}
          {checkPermission("hr_settings", "edit") && (
            <Route
              path="/view-user-access-level/:id"
              render={(props) => <EditRole />}
            />
          )}

          {/* eManage Settings */}
          {checkPermission("emanage_settings", "view") && (
            <Route
              path="/emanage-settings/:tab"
              render={(props) => <EManageSettings />}
            />
          )}

          {checkPermission("production_settings", "edit") && (
            <Route
              path="/production-settings/software-version/:id"
              render={(props) => <SoftwareVersions />}
            />
          )}

          {checkPermission("production_settings", "edit") && (
            <Route
              path="/production-settings/agenda/:id"
              render={(props) => <Agenda />}
            />
          )}

          {/* Production Settings */}
          {checkPermission("production_settings", "view") && (
            <Route
              path="/production-settings/agenda/:tab"
              render={(props) => <Agenda />}
            />
          )}

          {checkPermission("production_settings", "edit") && (
            <Route
              path="/production-settings/resource-planner-template/edit/:id"
              render={(props) => <ResourcePlannerTemplateForm />}
            />
          )}
          {checkPermission("production_settings", "add") && (
            <Route
              path="/production-settings/resource-planner-template/add"
              render={(props) => <ResourcePlannerTemplateForm />}
            />
          )}
          {checkPermission("production_settings", "edit") && (
            <Route
              path="/production-settings/resource-planner-template/clone"
              render={(props) => <ResourcePlannerTemplateForm />}
            />
          )}

          {/* Production Settings */}
          {checkPermission("production_settings", "view") && (
            <Route
              path="/production-settings/:tab"
              render={(props) => <ProductionSettings />}
            />
          )}

          {/* Employees */}
          {checkPermission("employees", "view_details") && (
            <Route
              path="/employees/details/:id"
              render={(props) => <EmployeeDetails />}
            />
          )}

          {checkPermission("employees", "edit") && (
            <Route
              path="/employees/edit/:id"
              render={(props) => <EmployeeForm />}
            />
          )}

          {checkPermission("employees", "add") && (
            <Route path="/employees/add" render={(props) => <EmployeeForm />} />
          )}

          {checkPermission("employees", "view") && (
            <Route path="/employees/:tab" render={(props) => <Employees />} />
          )}

          {/* Organisation */}
          {checkPermission("organisations", "add") && (
            <Route
              path="/organisations/add"
              render={(props) => <OrganisationForm />}
            />
          )}

          {checkPermission("organisations", "edit") && (
            <Route
              path="/organisations/edit/:id"
              render={(props) => <OrganisationForm />}
            />
          )}

          {checkPermission("organisations", "view_details") && (
            <Route
              path="/organisations/:tab/:id"
              render={(props) => <OrganisationsOverview />}
            />
          )}

          {checkPermission("organisations", "view") && (
            <Route
              path="/organisations"
              render={(props) => <Organisations />}
            />
          )}

          {/* Contact */}
          {checkPermission("contacts", "add") && (
            <Route path="/contacts/add" render={(props) => <ContactForm />} />
          )}

          {checkPermission("contacts", "edit") && (
            <Route
              path="/contacts/edit/:id"
              render={(props) => <ContactForm />}
            />
          )}

          {checkPermission("contacts", "view") && (
            <Route path="/contacts" render={(props) => <Contacts />} />
          )}

          {/* Task */}
          {/* {checkPermission('tasks', 'add') && (
            <Route path="/tasks/add" render={(props) => <TaskForm />} />
          )}
          {checkPermission('tasks', 'edit') && (
            <Route path="/tasks/edit/:id" render={(props) => <TaskForm />} />
          )}
          {checkPermission('tasks', 'view') && (
            <Route path="/tasks" render={(props) => <Tasks />} />
          )} */}

          {checkPermission("projects", "create") && (
            <Route
              path="/projects/add"
              render={(props) => <CreateProjectonbording />}
            />
          )}
          {checkPermission("projects", "view_details") && (
            <Route
              path="/projects/view/:id"
              render={(props) => <CreateProjectonbording />}
            />
          )}

          {checkPermission("projects", "view") && (
            <Route path="/projects/:tab" render={(props) => <Projectlist />} />
          )}

          <Route
            path="/panel-queries"
            render={(props) => <PanelQueriesListing />}
          />

          {checkPermission("sub_projects", "complete") && (
            <Route
              path="/post-implementation-review/:parentId/:childId"
              render={(props) => <EpirProject />}
            />
          )}

          {/* Client */}
          {checkPermission("clients", "view") && (
            <Route
              path="/clients/:id/:tab"
              render={(props) => <ClientsOverview />}
            />
          )}

          {checkPermission("clients", "view") && (
            <Route path="/clients" render={(props) => <Clients />} />
          )}

          {checkPermission("checklists", "view_checklist_details") && (
            <Route
              path="/checklist/details"
              render={(props) => <QualityCheck />}
            />
          )}

          {checkPermission("checklists", "view") && (
            <Route path="/checklist" render={(props) => <Checklist />} />
          )}

          {/* Subproject */}
          {checkPermission("sub_projects", "add") && (
            <Route
              path="/sub-projects/add"
              render={(props) => <AddSubProject />}
            />
          )}

          {checkPermission("sub_projects", "edit") && (
            <Route
              path="/sub-projects/edit/:id"
              render={(props) => <EditSubProject />}
            />
          )}

          {checkPermission("sub_projects", "view_details") && (
            <Route
              path="/sub-projects/:tab/:parentId/:childId"
              render={(props) => <SubProjectOverview />}
            />
          )}

          {checkPermission("sub_projects", "view") && (
            <Route
              path="/sub-projects/:tab"
              render={(props) => <SubProjectslist />}
            />
          )}

          <Route path="/test" render={(props) => <TestTail />} />
          <Route path="/eapps" render={(props) => <EApps />} />
          {/* ActivityFiles */}
          {checkPermission("activity_files", "add") && (
            <Route
              path="/activity-files/add"
              render={(props) => <AddActivity />}
            />
          )}
          {checkPermission("activity_files", "edit") && (
            <Route
              path="/activity-files/edit"
              render={(props) => <EditActivityFiles />}
            />
          )}
          {checkPermission("activity_files", "view") && (
            <Route
              path="/activity-files/:tab"
              render={(props) => <ActivityFileslist />}
            />
          )}

          {/* here */}
          {checkPermission("activity_files", "view_details") && (
            <Route
              path="/activity-file/:tab"
              render={(props) => <ActivityOverview />}
            />
          )}

          {/* <Route path="/timesheet" render={(props) => <TimeSheet />} /> */}
          {checkPermission("timesheets", "view") && (
            <Route path="/timesheets" render={(props) => <TimeSheet />} />
          )}

          {checkPermission("deliverables", "view") && (
            <Route
              path="/deliverables/:tab"
              render={(props) => <Deliverables />}
            />
          )}

          {/* Conference */}
          {checkPermission("conference", "view") && (
            <Route path="/conference/:tab" render={(props) => <Conference />} />
          )}
          {checkPermission("conference", "book") && (
            <Route
              path="/book-conference"
              render={(props) => <BookConference />}
            />
          )}
          {checkPermission("conference", "book") && (
            <Route
              path="/edit-book-conference/:parentId/:childId"
              render={(props) => <EditBookConference />}
            />
          )}
          {checkPermission("conference", "book") && (
            <Route
              path="/edit-book-conference-occurrence/:parentId/:childId"
              render={(props) => <EditOccurrence />}
            />
          )}
          {checkPermission("conference", "add") && (
            <Route
              path="/add-conference"
              render={(props) => <AddConference />}
            />
          )}
          {checkPermission("conference", "edit") && (
            <Route
              path="/edit-conference/:id"
              render={(props) => <EditConference />}
            />
          )}
          {checkPermission("conference", "view_details") && (
            <Route
              path="/view-conference/:id/:tab"
              render={(props) => <ConferenceViewDetails />}
            />
          )}

          {/* Checklist and QC */}
          {checkPermission("activity_files", "do_qc2") && (
            <Route path="/qc2" render={(props) => <QualityCheck />} />
          )}
          {checkPermission("activity_files", "complete") && (
            <Route path="/qc1" render={(props) => <QualityCheck />} />
          )}

          {/* Project Queries */}
          {/* Listing of Project queries count with sub project, project and client */}
          {checkPermission("pqs", "raise_query") && (
            <Route
              path="/project-queries/raise"
              render={(props) => <RaiseQuery />}
            />
          )}

          {checkPermission("pqs", "view_queries_by_subproject") && (
            <Route
              path="/project-queries/view-by-sub-project/:subProjectId"
              render={(props) => <ProjectQuerySubListing />}
            />
          )}

          {checkPermission("pqs", "raise_query") && (
            <Route
              path="/project-queries/draft/:id"
              render={(props) => <RaiseQuery />}
            />
          )}

          {checkPermission("pqs", "view") && (
            <Route
              path="/project-queries"
              render={(props) => <ProjectQueries />}
            />
          )}

          {checkPermission("pqs", "add_feedback") && (
            <Route
              path="/project-query-feedback/:id"
              render={(props) => <QueriesFeedback />}
            />
          )}
          {checkPermission("pqs", "view_details") && (
            <Route
              path="/project-query-overview/:id"
              render={(props) => <ViewQuery />}
            />
          )}

          {/* Variation Orders */}
          {/* Issue Variation Order */}
          {checkPermission("vo", "issue_order") && (
            <Route
              path="/variation-orders/issue"
              render={(props) => <IssueOrder />}
            />
          )}

          {checkPermission("vo", "view_orders_by_subproject") && (
            <Route
              path="/variation-orders/view-by-sub-project/:subProjectId"
              render={(props) => <VariationOrderSubListing />}
            />
          )}

          {checkPermission("vo", "view_details") && (
            <Route
              path="/variation-orders/details/:id"
              render={(props) => <ViewOrder />}
            />
          )}

          {checkPermission("vo", "add_feedback") && (
            <Route
              path="/variation-orders/add-feedback/:id"
              render={(props) => <OrdersFeedback />}
            />
          )}

          {checkPermission("vo", "issue_order") && (
            <Route
              path="/variation-orders/draft/:id"
              render={(props) => <IssueOrder />}
            />
          )}

          {/* Listing of Variation orders count with sub project, project and client */}
          {checkPermission("vo", "view") && (
            <Route
              path="/variation-orders"
              render={(props) => <VariationOrders />}
            />
          )}

          {/* <Route path="/reports" render={(props) => <Reports />} /> */}
          {checkPermission("reports", "view_report_builder") && (
            <Route
              path="/add-reports-builder"
              render={(props) => <ReportBuilder />}
            />
          )}
          {checkPermission("reports", "view_report_builder") && (
            <Route
              path="/edit-reports-builder/:reportId"
              render={(props) => <ReportBuilder />}
            />
          )}
          {checkPermission("reports", "view_report_builder") && (
            <Route path="/reports-builder" render={(props) => <Reports />} />
          )}

          {checkPermission("reports", "view_reports") && (
            <Route path="/reports/:tab" render={(props) => <Reportlist />} />
          )}

          <Route path="/analytical-reports" render={(props) => <AnalyticalReport />} />

          {checkPermission("meeting_notes", "view_details") && (
            <Route
              path="/meeting-notes/details/:id"
              render={(props) => <MeetingDetails />}
            />
          )}
          {checkPermission("meeting_notes", "view") && (
            <Route
              path="/meeting-notes/:tab"
              render={(props) => <MinutesOfMeeting />}
            />
          )}

          {checkPermission("meeting_notes", "add") && (
            <Route
              path="/add-meeting-notes"
              render={(props) => <MeetingNotesForm />}
            />
          )}
          {checkPermission("meeting_notes", "edit") && (
            <Route
              path="/edit-meeting-notes/:id"
              render={(props) => <MeetingNotesForm />}
            />
          )}

          <Route path="/sql-builder" render={(props) => <SQLBuilder />} />

          {/* Trash */}
          {checkPermission("trash", "view") && (
            <Route path="/trash/:tab" render={(props) => <Trash />} />
          )}

          {/*client Feedback*/}
          {checkPermission("client_feedback", "add") && (
            <Route
              path="/feedback/add"
              render={(props) => <ClientFeedbackForm />}
            />
          )}
          {checkPermission("client_feedback", "edit") && (
            <Route
              path="/feedback/edit/:id"
              render={(props) => <ClientFeedbackForm />}
            />
          )}
          {checkPermission("client_feedback", "view_details") && (
            <Route
              path="/feedback/details/:id"
              render={(props) => <ClientFeddbackDetails />}
            />
          )}
          {checkPermission("client_feedback", "view") && (
            <Route path="/feedback" render={(props) => <ClientFeedback />} />
          )}

          {/* Resource Planner */}
          {checkPermission("resource_planner", "view") && (
            <Route
              path="/resource-planner"
              render={(props) => <ResourcePlanner />}
            />
          )}
          {checkPermission("resource_planner", "add") && (
            <Route
              path="/resource-planner-add/"
              render={(props) => <ResourcePlannerForm />}
            />
          )}
          {checkPermission("resource_planner", "edit") && (
            <Route
              path="/resource-planner-edit/:id"
              render={(props) => <ResourcePlannerForm />}
            />
          )}

          {/* Util Routes */}
          <Route path="/loader" render={(props) => <Loader />} />
          <Route path="/allicons" render={(props) => <Allicons />} />

          {/* Redirect to Dashboard if not found on the above */}
          <Route path="/test" render={(props) => <Demo />} />
          <Route path="*" render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </Main>
    </>
  );
};

export default MainRoutes;
